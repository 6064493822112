<template>
  <div id="step-1">
    <div
      class="step-title mb-2 adjust-space"
      v-if="this.$route.name !== 'forgetpassword'"
    >
      <h4 class="submit-section-header step-title__title mb-2">رقم الجوال</h4>
      <p class="step-title__subtitle">من فضلك أدخل رقم الجوال الخاص بك</p>
    </div>

    <div class="step-title mb-2" v-else>
      <h4 class="submit-section-header">اعادة تعيين كلمة المرور</h4>
      <p>من فضلك أدخل رقم الجوال الذي سجلت به من قبل</p>
    </div>

    <b-form>
      <div class="form-row form-row-wide">
        <p>
          <label for="phone-no" class="m-0">
            <vue-tel-input
              id="register-phoneNumber"
              v-model="phoneNumber"
              @keydown.native.space.prevent
              @keydown.native.enter.prevent="validate"
              placeholder="ادخل رقم الجوال"
              :only-countries="['SA']"
              default-country="SA"
              :class="{
                'has-error': !isValidPhone && submitted,
                'is-valid': isValidPhone
              }"
              :required="true"
              @validate="onValidatePhoneNumber"
            >
            </vue-tel-input>
          </label>
        </p>
      </div>
      <!--  -->
      <div
        v-if="this.$route.name !== 'forgetpassword'"
        class="form-row mt-4 form-row-wide"
      >
        <div class="row mb-4 step-title">
          <div class="col-12">
            <p>
              <label for="password">
                <h4 class="mb-3 step-title__title">كلمة المرور</h4>
                <i class="hi-lock"></i>
                <b-form-input
                  @keydown.space="event => event.preventDefault()"
                  id="password register-password"
                  v-model="password"
                  class="input-text"
                  :type="passwordFieldType"
                  placeholder="ادخل كلمة المرور"
                />
                <i
                  class="show-hide-password"
                  :class="{
                    'hi-eye': passwordFieldType === 'password',
                    'hi-eye-crossed': passwordFieldType === 'text'
                  }"
                  @click="switchVisibility"
                ></i>
              </label>
              <!-- <span v-if="submitted && !$v.password.goodPassword" class="invalid-feedback d-block">يجب الا تقل عن ٨ أحرف وتحتوي على حرف كبير وأخر صغير ورقم.</span> -->
            </p>
          </div>
        </div>
      </div>
    </b-form>

    <div
      v-if="this.$route.name !== 'forgetpassword'"
      class="form-row form-row-wide mt-3 mx-1"
    >
      <label for="rememberme" class="rememberme margin-top-0 margin-bottom-0"
        ><input
          id="rememberme"
          name="rememberme"
          type="checkbox"
          value="forever"
          v-model="agreement"
        />
        أوافق على
        <router-link :to="{ name: 'help-center' }" target="_blank">
          الشروط والأحكام سياسة الخصوصية
        </router-link>
        &nbsp; الخاصة بموقع هكتار</label
      >
    </div>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import config from '@/config'

export default {
  components: {
    VueTelInput
  },
  data() {
    return {
      phoneNumber: null,
      password: '',
      passwordFieldType: 'password',
      phoneData: {},
      submitted: false,
      agreement: false,
      baseUrl: config.BASE_URL
    }
  },
  computed: {
    isValidPhone() {
      return this.phoneData.valid ? this.phoneData.valid : false
    }
  },

  methods: {
    onValidatePhoneNumber(data) {
      this.phoneData = data
    },
    validate() {
      this.submitted = true

      if (!this.isValidPhone) {
        return this.$toasted.show('من فضلك تأكد من رقم الهاتف', {
          duration: 2000,
          type: 'error',
          icon: 'fa-times'
        })
      }

      if (this.$route.name !== 'forgetpassword' && !this.password) {
        return this.$toasted.show('كلمة المرور مطلوبه', {
          duration: 2000,
          type: 'error',
          icon: 'fa-times'
        })
      }

      if (this.$route.name !== 'forgetpassword' && this.password.length < 6) {
        return this.$toasted.show(
          'لا يجب أن يقل عدد أحرف/أرقام كلمة المرور عن 6',
          {
            duration: 2000,
            type: 'error',
            icon: 'fa-times'
          }
        )
      }

      if (this.$route.name !== 'forgetpassword' && !this.agreement) {
        return this.$toasted.show('يجب الموافقة على الشروط والأحكام', {
          duration: 2000,
          type: 'error',
          icon: 'fa-times'
        })
      }

      this.$emit('on-validate', this.phoneData, this.password)

      // always return false as we move in wizard depend on API call response in Login.vue component.
      return false
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    }
  }
}
</script>
<style lang="scss" scoped>
input[type='checkbox'] {
  filter: hue-rotate(280deg);
  margin: 10px;
  cursor: pointer;
}
.step-title {
  &__title {
    font-size: 16px;
  }
  &__subtitle {
    font-size: 14px;
  }
}
.rememberme {
  margin-right: -16px;
}
.adjust-space {
  margin-right: -5px;
}
</style>
