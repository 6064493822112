<template>
  <div id="vie-otp-input-wrap">
    <vie-otp-input
      ref="otpInput"
      input-classes="otp-input"
      :num-inputs="4"
      separator=""
      :should-auto-focus="true"
      @on-change="handleOnChange"
      @on-complete="handleOnComplete"
    />
    <b-button
      type="submit"
      variant="primary"
      class="mr-auto mt-5"
      :disabled="TimePassed"
      @click.prevent="resendCode"
    >
      <span
        ><span v-if="timeRun > 0" class="mx-1 font-weight-bold">{{
          timeRun
        }}</span>
        اعاده ارسال الرمز</span
      >
    </b-button>
  </div>
</template>

<script>
import VieOtpInput from '@bachdgvn/vue-otp-input'
import { userService } from '@/services'

export default {
  components: {
    VieOtpInput
  },
  props: ['phoneNumber', 'password'],
  data() {
    return {
      enteredOtp: null,
      TimePassed: true,
      timeRun: 30,
      timerInterval: null
    }
  },
  mounted() {
    this.timer()
  },
  watch: {
    timeRun(newValue) {
      if (newValue === 0) {
        this.clearTimer(this.timerInterval)
      }
    }
  },
  methods: {
    validate() {
      let phoneNumber = this.phoneNumber
      let otp = this.enteredOtp
      if (!otp || otp.length < 4) {
        return this.$toasted.show('من فضلك تأكد من رقم التحقيق', {
          duration: 2000,
          type: 'error',
          icon: 'fa-times'
        })
      }
      this.$emit('on-validate', {
        phoneNumber,
        password: this.password,
        confirmationCode: otp,
        showMsg: true
      })
      return false
    },
    handleOnComplete(otp) {
      this.enteredOtp = otp
      let phoneNumber = this.phoneNumber
      this.$emit('on-validate', {
        phoneNumber,
        password: this.password,
        confirmationCode: otp,
        showMsg: false
      })
      return false
    },
    handleOnChange(otp) {
      this.enteredOtp = otp
      return false
    },
    resendCode() {
      userService.resendCode(this.phoneNumber).then(res => {
        if (!res.data.result)
          return this.$toasted.show('حدث خطأ ، الرجاء المحاولة مرة أخرى', {
            duration: 2000,
            type: 'error',
            icon: 'hi-close'
          })
        this.$toasted.show('تم اعاده ارسال الرمز', {
          duration: 2000,
          type: 'success',
          icon: 'hi-check'
        })
        this.timeRun = 30
        this.TimePassed = true
        this.timer()
      })
    },
    timer() {
      this.timerInterval = setInterval(() => {
        this.timeRun--
      }, 9000)
    },
    clearTimer(val) {
      clearInterval(val)
      this.TimePassed = false
    }
  }
}
</script>

<style>
#vie-otp-input-wrap > div {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 -10px;
}
#vie-otp-input-wrap > div > div {
  flex-grow: 1;
  margin: 0 10px;
}

.otp-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: center;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.otp-input.error {
  border: 1px solid red !important;
}
</style>
